.photo-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: calc(100vh - 90px);
    width: calc(100% - 40px);
    max-height: 100%;
    max-width: 100%;
    gap: 10px;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 20px;

    img {
        flex-grow: 1;
        flex-shrink: 1;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        margin: auto;
        overflow: hidden;
    }

    .photo-info {
        flex-shrink: 0;
        flex-grow: 0;
        padding: 20px;
    }
  }