.photos-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-top: 20px;

    .photo-card {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media only screen and (max-width: 992px) {
        .MuiImageList-root {
          column-count: 1 !important;
        }
      }

    @media only screen and (max-width: 1200px) and (min-width: 992px) {
        .MuiImageList-root {
          column-count: 2 !important;
        }
      }

      @media only screen and (max-width: 1600px) and (min-width: 1200px) {
        .MuiImageList-root {
          column-count: 3 !important;
        }
      }

      @media only screen and (max-width: 2000px) and (min-width: 1600px) {
        .MuiImageList-root {
          column-count: 4 !important;
        }
      }

      @media only screen and (max-width: 2400px) and (min-width: 2000px) {
        .MuiImageList-root {
          column-count: 5 !important;
        }
      }

      @media only screen and (max-width: 2800px) and (min-width: 2400px) {
        .MuiImageList-root {
          column-count: 6 !important;
        }
      }

      @media only screen and (max-width: 3200px) and (min-width: 2800px) {
        .MuiImageList-root {
          column-count: 7 !important;
        }
      }

     @media only screen and (min-width: 3200px) {
        .MuiImageList-root {
          column-count: 8 !important;
        }
      }

    .cell {
        position: relative;
        cursor: pointer;

        img {
            width: 100%;
        }
    }

    .panorama {
      position: relative;
      cursor: pointer;
      width: 100%;
      margin-bottom: 5px;

      img {
          width: 100%;
      }
    }
  }