.main-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  height: 100vh;
  width: 100%;
  border-radius: 0 !important;

  .page-content {
    flex-grow: 1;
    max-width: 100%;
    width: 100%;
  }
}