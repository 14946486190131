.home-page {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    width: 100%;
    padding: 0;;

    .welcome-text {
        align-self: center;
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 22px;
        /* font-family: 'ValeterFont'; */
        margin-left: 50px;
        margin-right: 50px;
        max-width: 70vw;
        text-align: center;
    }

    .react-slideshow-container {
        width: 75vw;
        max-width: 900px;
        margin: auto;
        margin-top: 20px;
    }

    .each-slide-effect > div {
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        height: 50vw;
        width: 75vw;
        max-width: 900px;
        max-height: 600px;

        img {
            flex-grow: 1;
            flex-shrink: 1;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            margin: auto;
            overflow: hidden;
        }
      }
  }