.galleries-by-category-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-top: 20px;

    .gallery-card {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .cell {
        position: relative;
        cursor: pointer;
        padding: 5px;

        img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
        }
    }

    .cell::before {
        content: '';
        display: block;
        padding-top: 80%; /* 5:4 Aspect Ratio */
    }

    .cell-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
  }