.contacts-page {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 20px;

    .contact-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 190px;
    }
  }